import { WarningIcon } from './WarningIcon'

/**
 * お問い合わせformエラーブロック
 */
/** */
export const FormError = () => {
  return (
    <div className="mt-12">
      <div className="flex items-start tab:items-center px-3 py-4 bg-[#FFF2F2] border border-primary">
        <WarningIcon />
        <p className="text-left pc:text-center text-primary text-sm font-bold">
          入力内容に問題があります。確認して、再度お試しください。
        </p>
      </div>
    </div>
  )
}
