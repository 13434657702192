import React, { useState } from 'react'
import { FormInputType } from '../../components/organisms/Form/FormInputType'
import { LayoutNoSideNav } from '../../components/organisms/Layout/LayoutNoSideNav'
import { FormStatusType } from '../../components/organisms/Form/FormStatusType'
import { SEO } from '../../components/data/SEO'
import { ENGLISH_TITLE } from '../../components/data/English'
import { FormStatus } from '../../components/organisms/Form/FormStatus'
import { Form } from '../../components/organisms/Form/Form'
import { FormConfirm } from '../../components/organisms/Form/FormConfirm'

const Page = () => {
  const [status, setStatus] = useState<FormStatusType>('入力')
  const [formInput, setFormInput] = useState<FormInputType>({
    about: '',
    company: '',
    firstName: '',
    lastName: '',
    tel1: '',
    tel2: '',
    tel3: '',
    email: '',
    zipcode1: '',
    zipcode2: '',
    address: '',
    content: '',
    privacy: '',
  })

  // useLoadRemoteScript('#test', 'https://sdk.form.run/js/v2/embed.js')

  return (
    <LayoutNoSideNav
      heading="お問い合わせ"
      headingEnglish={ENGLISH_TITLE.Contact}
      BreadcrumbData={[{ title: 'お問い合わせ', url: '' }]}
    >
      <div className="pc:py-12 pc:px-8 bg-white">
        <FormStatus status={status} />
        <div>
          {status === '入力' ? (
            <Form
              formInput={formInput}
              setFormInput={setFormInput}
              setStatus={setStatus}
            />
          ) : (
            <FormConfirm
              formInput={formInput}
              setStatus={setStatus}
              setFormInput={setFormInput}
            />
          )}
        </div>
        {/* <div id="test">
          <div
            className="formrun-embed"
            data-formrun-form="@crghd-webmaster-1669864123"
            data-formrun-redirect="true"
          />
        </div> */}
      </div>
    </LayoutNoSideNav>
  )
}

export default Page

export const Head = () => <SEO title="お問い合わせ" url="/contact" />
