export const WarningIcon = () => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9891 20.3158C15.2786 20.3158 15.5139 20.2223 15.6948 20.0353C15.8758 19.8484 15.9662 19.6053 15.9662 19.3061C15.9662 19.0219 15.8758 18.7789 15.6948 18.5769C15.5139 18.375 15.2786 18.2741 14.9891 18.2741C14.6996 18.2741 14.4644 18.375 14.2835 18.5769C14.1025 18.7789 14.0121 19.0219 14.0121 19.3061C14.0121 19.6053 14.1025 19.8484 14.2835 20.0353C14.4644 20.2223 14.6996 20.3158 14.9891 20.3158ZM14.9891 24.8031C13.7443 24.8031 12.579 24.5601 11.4934 24.0739C10.4077 23.5878 9.45597 22.9222 8.63812 22.0771C7.82027 21.232 7.17612 20.2485 6.70567 19.1266C6.23522 18.0048 6 16.8007 6 15.5143C6 14.213 6.23522 13.0014 6.70567 11.8796C7.17612 10.7578 7.81665 9.77803 8.62726 8.9404C9.43788 8.10276 10.3896 7.43714 11.4825 6.94354C12.5754 6.44993 13.7443 6.20312 14.9891 6.20312C16.2485 6.20312 17.4246 6.44993 18.5175 6.94354C19.6104 7.43714 20.5585 8.09902 21.3619 8.92918C22.1653 9.75934 22.8058 10.7391 23.2835 11.8684C23.7612 12.9977 24 14.213 24 15.5143C24 16.8007 23.7612 18.0086 23.2835 19.1379C22.8058 20.2672 22.1616 21.2507 21.351 22.0883C20.5404 22.9259 19.5923 23.5878 18.5066 24.0739C17.421 24.5601 16.2485 24.8031 14.9891 24.8031ZM15.0543 16.5913C15.3004 16.5913 15.5066 16.5053 15.6731 16.3333C15.8396 16.1613 15.9228 15.9481 15.9228 15.6938V11.5879C15.9228 11.3336 15.8396 11.1205 15.6731 10.9485C15.5066 10.7765 15.3004 10.6905 15.0543 10.6905C14.8082 10.6905 14.6019 10.7765 14.4355 10.9485C14.269 11.1205 14.1858 11.3336 14.1858 11.5879V15.6938C14.1858 15.9481 14.269 16.1613 14.4355 16.3333C14.6019 16.5053 14.8082 16.5913 15.0543 16.5913Z"
        fill="#CC050B"
      />
    </svg>
  )
}
