import { css } from '@emotion/react'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

export const useModal = () => {
  const [show, setShow] = useState(false)

  const openModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }

  const Modal = ({
    children,
    show,
  }: {
    children: ReactNode
    show: boolean
  }) => {
    const contentRef = useRef(null)
    const [rootElment, setRootElement] = useState<HTMLElement | null>(null)

    useEffect(() => {
      setRootElement(document.getElementById('layout-root'))
      if (contentRef.current === null) return

      if (show) {
        disableBodyScroll(contentRef.current, {
          reserveScrollBarGap: true,
        })
      } else {
        enableBodyScroll(contentRef.current)
      }

      return () => {
        clearAllBodyScrollLocks()
      }
    }, [show, contentRef])

    if (!show || !rootElment) return null

    return createPortal(
      <div
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.3s;
          z-index: 999;
        `}
      >
        <div
          css={css`
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.7);
            transition: 0.3s;
          `}
        ></div>
        <div ref={contentRef}>{children}</div>
      </div>,
      rootElment
    )
  }
  return { Modal, openModal, closeModal, show }
}
