import { css } from '@emotion/react'
import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { styleButtonPrimary } from '../../atoms/Button/ButtonPrimary'
import { useModal } from '../../hooks/useModal'
import { formStyle } from '../../styles/formStyle'
import { PrivacyPolicy } from '../PrivacyPolicy/PrivacyPolicy'
import { FormError } from './FormError'
import { FormInputType } from './FormInputType'
import { FormStatusType } from './FormStatusType'
import { WarningIcon } from './WarningIcon'

type Zipcode = {
  main: string
  sub: string
}
type Address = {
  address1: string
  address2: string
  address3: string
}

/**
 * お問い合わせform本体
 */
/** */
export const Form = ({
  formInput,
  setFormInput,
  setStatus,
}: {
  formInput: FormInputType
  setFormInput: React.Dispatch<React.SetStateAction<FormInputType>>
  setStatus: React.Dispatch<React.SetStateAction<FormStatusType>>
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormInputType>({ mode: 'onBlur', criteriaMode: 'all' })

  const onSubmit: SubmitHandler<FormInputType> = (data) => {
    setFormInput((prev) => ({ ...prev, ...data }))
    setStatus('確認')
    window.scrollTo(0, 0)
  }

  const [isSubmitButtonActive, setIsSubmitButtonActive] = useState(false)

  const [zipcode, setZipcode] = useState<Zipcode>({
    main: formInput.zipcode1,
    sub: formInput.zipcode2,
  })
  const [addressFetched, setAddressFetched] = useState<Address>({
    address1: '',
    address2: '',
    address3: '',
  })
  const [addressValue, setAddressValue] = useState(formInput.address)
  const [isSearching, setIsSearching] = useState(false)

  const searchAddress = async () => {
    if (zipcode.main.length === 3 && zipcode.sub.length === 4) {
      try {
        setIsSearching(true)
        const res = await axios.get(
          'https://zipcloud.ibsnet.co.jp/api/search',
          {
            params: {
              zipcode: zipcode.main + zipcode.sub,
            },
          }
        )

        if (!res.data.results) {
          throw new Error('住所の取得に失敗しました。')
        }

        const result = res.data.results[0]
        setAddressFetched((prevAddress) => ({
          ...prevAddress,
          address1: result['address1'],
          address2: result['address2'],
          address3: result['address3'],
        }))
      } catch {
        alert('住所の取得に失敗しました。')
      } finally {
        setIsSearching(false)
      }
    } else {
      alert('正しい形式の郵便番号を入力してください。')
    }
  }

  const isFirstRender = useRef(false)

  useEffect(() => {
    // 初回レンダー時のみ呼ばれるフラグ
    isFirstRender.current = true
  }, [])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      // 住所検索結果取得したら更新
      setValue('address', Object.values(addressFetched).join(''))
    }
  }, [addressFetched, setValue])

  const { Modal, openModal, closeModal, show } = useModal()

  return (
    <div>
      {Object.keys(errors).length ? (
        <FormError />
      ) : (
        <p className="mt-12 leading-normal text-center">
          お問い合わせの際は、
          <br className="pc:hidden" />
          下記のフォームをご利用ください。
        </p>
      )}

      <form css={formStyle} onSubmit={handleSubmit(onSubmit)}>
        <div className="pt-12">
          <div className="form__label">
            <span className="form__required">必須</span>
            <p>お問い合わせ種別</p>
          </div>
          <ul className="radioStyle flex pc:flex-row space-y-6 pc:space-y-0 flex-col pc:items-center pc:space-x-12">
            {[
              { id: 'aboutCompany', value: '会社について' },
              { id: 'aboutIR', value: 'IRについて' },
              { id: 'aboutRecruit', value: '採用について' },
              { id: 'aboutOthers', value: 'その他' },
            ].map((item) => (
              <li key={item.id}>
                <label htmlFor={item.id}>
                  <input
                    {...register('about', {
                      required: 'お問い合わせの種類を選択してください。',
                    })}
                    type="radio"
                    id={item.id}
                    value={item.value}
                    defaultChecked={
                      formInput.about
                        ? formInput.about === item.value
                        : item.id === 'aboutCompany'
                    }
                  />
                  <span>{item.value}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>

        <div className="form__section">
          <label htmlFor="company" className="form__label">
            <span className="form__required">必須</span>
            <p>企業・団体・組織名</p>
          </label>
          <input
            id="company"
            placeholder="例：CRGホールディングス株式会社"
            defaultValue={formInput.company}
            className={`input_field w-full ${
              errors.company ? 'border-primary border' : ''
            }`}
            {...register('company', { required: true })}
          />
          {errors.company && (
            <p className="flex items-center mt-2">
              <WarningIcon />
              <span className="text-primary">ご入力をお願いします</span>
            </p>
          )}
        </div>

        <div className="form__section">
          <label htmlFor="lastName" className="form__label">
            <span className="form__required">必須</span>
            <p>お名前</p>
          </label>
          <input
            id="lastName"
            placeholder="姓：山田"
            defaultValue={formInput.lastName}
            className={`input_field w-[calc(50%-12px)] pc:w-[200px] ${
              errors.lastName ? 'border-primary border' : ''
            }`}
            {...register('lastName', { required: true })}
          />
          <input
            id="firstName"
            placeholder="名：太郎"
            defaultValue={formInput.firstName}
            className={`input_field ml-6 w-[calc(50%-12px)] pc:w-[200px] ${
              errors.firstName ? 'border-primary border' : ''
            }`}
            {...register('firstName', { required: true })}
          />
          {(errors.lastName || errors.firstName) && (
            <p className="flex items-center mt-2">
              <WarningIcon />
              <span className="text-primary">ご入力をお願いします</span>
            </p>
          )}
        </div>

        <div className="form__section">
          <label htmlFor="tel1" className="form__label">
            <span className="form__required">必須</span>
            <p>電話番号</p>
          </label>
          <input
            id="tel1"
            placeholder="03"
            defaultValue={formInput.tel1}
            className={`input_field w-[80px] ${
              errors.tel1 ? 'border-primary border' : ''
            }`}
            {...register('tel1', {
              required: true,
              pattern: /^[0-9]+$/,
            })}
          />
          <span className="ml-3">-</span>
          <input
            id="tel2"
            placeholder="3345"
            defaultValue={formInput.tel2}
            className={`input_field ml-3 w-[80px] ${
              errors.tel2 ? 'border-primary border' : ''
            }`}
            {...register('tel2', { required: true, pattern: /^[0-9]+$/ })}
          />
          <span className="ml-3">-</span>
          <input
            id="tel3"
            placeholder="2772"
            defaultValue={formInput.tel3}
            className={`input_field ml-3 w-[80px] ${
              errors.tel3 ? 'border-primary border' : ''
            }`}
            {...register('tel3', { required: true, pattern: /^[0-9]+$/ })}
          />
          {(errors.tel1?.types?.required ||
            errors.tel2?.types?.required ||
            errors.tel3?.types?.required) && (
            <p className="flex items-center mt-2">
              <WarningIcon />
              <span className="text-primary">ご入力をお願いします</span>
            </p>
          )}
          {(errors.tel1?.types?.pattern ||
            errors.tel2?.types?.pattern ||
            errors.tel3?.types?.pattern) && (
            <p className="flex items-center mt-2">
              <WarningIcon />
              <span className="text-primary">半角英数字でご入力ください</span>
            </p>
          )}
        </div>

        <div className="form__section">
          <label htmlFor="email" className="form__label">
            <span className="form__required">必須</span>
            <p>メールアドレス</p>
          </label>
          <input
            id="email"
            placeholder="例：crghd-info@cr2.co.jp"
            defaultValue={formInput.email}
            className={`input_field w-full ${
              errors.email ? 'border-primary border' : ''
            }`}
            {...register('email', {
              required: true,
              pattern:
                /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
            })}
          />
          {errors.email?.types?.required && (
            <p className="flex items-center mt-2">
              <WarningIcon />
              <span className="text-primary">ご入力をお願いします</span>
            </p>
          )}
          {errors.email?.types?.pattern && (
            <p className="flex items-center mt-2">
              <WarningIcon />
              <span className="text-primary">正しい形式でご入力ください</span>
            </p>
          )}
        </div>

        <div className="form__section">
          <label htmlFor="zipcode1" className="form__label">
            <span className="form__option">任意</span>
            <p>郵便番号</p>
          </label>
          <input
            id="zipcode1"
            placeholder="163"
            value={zipcode.main}
            className={`input_field w-[80px] ${
              errors.zipcode1 ? 'border-primary border' : ''
            }`}
            {...register('zipcode1', {
              pattern: /^[0-9]{3}$/,
              onChange: (e) => setZipcode({ ...zipcode, main: e.target.value }),
            })}
          />
          <span className="ml-3">-</span>
          <input
            id="zipcode2"
            placeholder="0437"
            value={zipcode.sub}
            className={`input_field ml-3 w-[80px] ${
              errors.zipcode2 ? 'border-primary border' : ''
            }`}
            {...register('zipcode2', {
              pattern: /^[0-9]{4}$/,
              onChange: (e) => setZipcode({ ...zipcode, sub: e.target.value }),
            })}
          />
          <div className="pc:inline-flex ml-12 hidden items-center">
            <button
              type="button"
              onClick={searchAddress}
              disabled={isSearching}
              className={`px-8 py-3 text-white text-center ${
                isSearching ? 'bg-text20' : 'bg-text10'
              }`}
            >
              {isSearching ? `検索中...` : `住所検索`}
            </button>
            <p className="text-sm ml-4">郵便番号から住所を自動入力できます</p>
          </div>
          {(errors.zipcode1 || errors.zipcode2) && (
            <p className="flex items-center mt-2">
              <WarningIcon />
              <span className="text-primary">
                正しい形式でご入力ください(半角英数字3桁-4桁)
              </span>
            </p>
          )}
          <div className="mt-6 pc:hidden">
            <button
              type="button"
              onClick={searchAddress}
              disabled={isSearching}
              className={`px-5 py-3 text-white text-center ${
                isSearching ? 'bg-text20' : 'bg-text10'
              }`}
            >
              {isSearching ? `検索中...` : `住所検索`}
            </button>
            <p className="text-sm mt-3">郵便番号から住所を自動入力できます</p>
          </div>
        </div>

        <div className="form__section">
          <label htmlFor="address" className="form__label">
            <span className="form__option">任意</span>
            <p>ご住所</p>
          </label>
          <input
            id="address"
            placeholder="例：東京都新宿区西新宿2-1-1 新宿三井ビルディング37階"
            value={addressValue}
            className={`input_field w-full ${
              errors.address ? 'border-primary border' : ''
            }`}
            {...register('address', {
              onChange: (e) => setAddressValue(e.target.value),
            })}
          />
        </div>

        <div className="form__section">
          <label htmlFor="content" className="form__label">
            <span className="form__required">必須</span>
            <p>お問い合わせ内容</p>
          </label>
          <textarea
            id="content"
            rows={10}
            placeholder="お問い合わせ内容をご記入ください"
            defaultValue={formInput.content}
            className={`input_field w-full ${
              errors.content ? 'border-primary border' : ''
            }`}
            {...register('content', { required: true })}
          />
          {errors.content && (
            <p className="flex items-center mt-2">
              <WarningIcon />
              <span className="text-primary">ご入力をお願いします</span>
            </p>
          )}
        </div>

        <div className="pt-14">
          <p className="font-bold text-md leading-normal">
            個人情報の取り扱いについて
          </p>
          <p className="leading-normal text-md mt-2">
            当社が皆さまよりお預かりする個人情報に関する取り扱いについて、「
            <span
              onClick={openModal}
              className="text-primary underline cursor-pointer"
            >
              プライバシーポリシー
            </span>
            」をお読みいただき、同意のうえお問い合わせください。
          </p>
          <div className="mt-6 radioStyle">
            <label htmlFor="privacy">
              <input
                {...register('privacy', {
                  required: true,
                  onChange: () => setIsSubmitButtonActive(true),
                })}
                type="radio"
                id="privacy"
                value="privacy"
              />
              <span>プライバシーポリシーに同意する</span>
            </label>
          </div>
        </div>

        <Modal show={show}>
          <div className="relative">
            <div className="bg-white w-[calc(100vw-40px)] pc:w-[800px] h-[calc(100vh-200px)] pc:h-[480px] overflow-auto px-6 pc:px-8 py-12">
              <h2 className="pb-10 text-xl font-bold">プライバシーポリシー</h2>
              <PrivacyPolicy />
            </div>
            <button
              className="absolute flex items-center font-bold text-white -bottom-11 left-[calc(50%-41px)]"
              onClick={closeModal}
            >
              <span className="pr-1 text-xxl">×</span>
              <span>閉じる</span>
            </button>
          </div>
        </Modal>

        <div className="w-[240px] pt-8 mx-auto">
          <button
            css={css`
              ${styleButtonPrimary}
              ${isSubmitButtonActive
                ? ``
                : `
              opacity : 0.3;
              background-color:#AAAAAA;
              cursor:default;
              &:before{
                display:none;
              }
              &:hover{
                &:after{
                  transform: translateX(0);
                }
              }
              `}
            `}
            className={`inline-flex cursor-pointer justify-center min-w-[240px] p-6 text-white text-md font-bold`}
            type="submit"
            disabled={!isSubmitButtonActive}
          >
            <span>入力内容を確認する</span>
          </button>
        </div>
      </form>
    </div>
  )
}
