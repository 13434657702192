import { css } from '@emotion/react'
import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { styleButtonPrimary } from '../../atoms/Button/ButtonPrimary'
import { colorList } from '../../styles/color'
import { formStyle } from '../../styles/formStyle'
import { FormError } from './FormError'
import { FormInputType } from './FormInputType'
import { FormStatusType } from './FormStatusType'

/**
 * お問い合わせform確認画面
 */
/** */
export const FormConfirm = ({
  formInput,
  setFormInput,
  setStatus,
}: {
  formInput: FormInputType
  setFormInput: React.Dispatch<React.SetStateAction<FormInputType>>
  setStatus: React.Dispatch<React.SetStateAction<FormStatusType>>
}) => {
  const [isError, setIsError] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const send = async () => {
    setIsSending(true)
    try {
      await axios.post('/contact/main.php', formInput)
      window.location.replace('/contact/finish')
    } catch (error) {
      setIsError(true)
      window.scrollTo(0, 0)
      setIsSending(false)
    }
  }
  return (
    <>
      {isError ? <FormError /> : ''}
      <div css={formStyle}>
        <div className="pt-12">
          <div className="form__label">
            <span className="form__required">必須</span>
            <p>お問い合わせ種別</p>
          </div>
          <p>{formInput.about}</p>
        </div>
        <div className="form__section">
          <div className="form__label">
            <span className="form__required">必須</span>
            <p>企業・団体・組織名</p>
          </div>
          <p>{formInput.company}</p>
        </div>
        <div className="form__section">
          <div className="form__label">
            <span className="form__required">必須</span>
            <p>お名前</p>
          </div>
          <p>
            {formInput.lastName} {formInput.firstName}
          </p>
        </div>
        <div className="form__section">
          <div className="form__label">
            <span className="form__required">必須</span>
            <p>電話番号</p>
          </div>
          <p>
            {formInput.tel1}-{formInput.tel2}-{formInput.tel3}
          </p>
        </div>
        <div className="form__section">
          <div className="form__label">
            <span className="form__required">必須</span>
            <p>メールアドレス</p>
          </div>
          <p>{formInput.email}</p>
        </div>
        <div className="form__section">
          <div className="form__label">
            <span className="form__option">任意</span>
            <p>郵便番号</p>
          </div>
          {formInput.zipcode1 && formInput.zipcode2 ? (
            <p>
              {formInput.zipcode1}-{formInput.zipcode2}
            </p>
          ) : (
            ''
          )}
        </div>
        <div className="form__section">
          <div className="form__label">
            <span className="form__option">任意</span>
            <p>ご住所</p>
          </div>
          <p>{formInput.address}</p>
        </div>
        <div className="form__section">
          <div className="form__label">
            <span className="form__required">必須</span>
            <p>お問い合わせ内容</p>
          </div>
          <p>{formInput.content}</p>
        </div>
        <div className="w-[240px] pt-8 mx-auto">
          <button
            onClick={send}
            disabled={isSending}
            css={css`
              ${styleButtonPrimary}
              ${isSending
                ? `
              opacity : 0.5;
              background-color:#AAAAAA;
              cursor:default;
              &:before{
                display:none;
              }
              &:hover{
                &:after{
                  transform: translateX(0);
                }
              }
              `
                : ``}
            `}
            className={`inline-flex cursor-pointer justify-center min-w-[240px] p-6 text-white text-md font-bold`}
          >
            <span>{isSending ? `送信中...` : `送信する`}</span>
          </button>
        </div>
        <div className="w-[240px] pt-6 mx-auto">
          <button
            onClick={() => {
              setStatus('入力')
              setFormInput((prev) => ({ ...prev, ...formInput }))
              window.scrollTo(0, 0)
            }}
            css={css`
              ${styleButtonPrimary}
              background-color: ${colorList.text10};
              &::before {
                background-color: ${colorList.primary};
              }
            `}
            className={`inline-flex cursor-pointer justify-center min-w-[240px] p-6 text-white text-md font-bold`}
          >
            <span>入力へ戻る</span>
          </button>
        </div>
      </div>
    </>
  )
}
