import { css } from '@emotion/react'
import { colorList } from './color'

export const formStyle = css`
  .radioStyle {
    input[type='radio'] {
      opacity: 0;
      position: absolute;
    }

    input[type='radio']:focus + span {
      outline: 1px solid #ccc;
    }

    // クリック範囲
    input[type='radio'] + span {
      display: inline-block;
      position: relative;
      padding: 0 0 0 23px;
      line-height: 1;
      vertical-align: middle;
      cursor: pointer;
      font-size: 16px;
    }

    // ラジオボタン周りの円
    input[type='radio'] + span::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 15px;
      height: 15px;
      border: 1px solid ${colorList.text20};
      border-radius: 50%;
      background: #fff;
    }
    input[type='radio']:checked + span::before {
      border: 1px solid ${colorList.primary};
    }

    //   ラジオボタン中身（未選択）
    input[type='radio'] + span::after {
      content: '';
      display: none;
    }

    //   ラジオボタン中身（選択）
    input[type='radio']:checked + span::after {
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      width: 9px;
      height: 9px;
      margin: 0;
      padding: 0;
      border-radius: 50%;
      background: ${colorList.primary};
    }
  }
  .form__section {
    padding-top: 56px;
  }

  .form__label {
    padding-bottom: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;

    span.form__required {
      color: white;
      font-size: 12px;
      padding: 4px;
      background-color: ${colorList.primary};
    }
    span.form__option {
      color: white;
      font-size: 12px;
      padding: 4px;
      background-color: ${colorList.text20};
    }
    p {
      padding-left: 8px;
    }
  }
  input.input_field,
  textarea.input_field {
    background-color: #f9f9f9;
    padding: 16px;
  }
`
